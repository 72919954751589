import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { Row, Col, Button } from "react-bootstrap";
import { client as prismicClient } from "../libs/prismic";
import Loader from "../components/Loader";
import { RichText } from "prismic-reactjs";

const PartnerWithUs = () => {
  const [partnerWithUsDoc, setPartnerWithUsDoc] = useState();
  const [content, setContent] = useState();

  useEffect(()=>{
    let content = partnerWithUsDoc?.data;
    setContent(content);

    if(!content) {
      return
    }

    content.banner_section.forEach(line => {
      if(line.text === '' && line.type === 'paragraph') {
        line.text = '\n';
      }
    })
    setContent(content);
  },[partnerWithUsDoc])

  useEffect(() => {
    async function fetchData() {
      const response = await prismicClient().getSingle("partner_with_us");
      setPartnerWithUsDoc(response);
    }
    fetchData();
  }, []);

  function Mailto({ email, subject, body, ...props }) {
    return (
      <a href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}>
        {props.children}
      </a>
    );
  }
  return (
    partnerWithUsDoc ? <Layout>
      <Row className="pt-80 pb-top-40 mw-1440 mx-auto justify-content-center padding-mobile mb-140">
        <Col md={12}>
          <div>
            <div className="rich-text">
              <RichText render={content?.banner_section} />
            </div>

            <Button className="contact-button">
              <Mailto
                email="partners@opalvest.comm"
                subject="Partner"
                body="Hello world!"
              >
                {content?.partner_with_us_button_text[0]?.text}
              </Mailto>
            </Button>
          </div>
        </Col>
      </Row>
    </Layout> : <Loader />
  );
};

export default PartnerWithUs;
